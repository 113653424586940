<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <form class="mt-4" v-on:submit.prevent="swapSong">
            <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
              <div class="col-span-2">
                <label for="link" class="font-semibold">Link 1 (*)</label>
                <input required v-model="link1" placeholder="" class="shadow-none with-border" id="link" type="text" autocomplete="off">
              </div>
              <div class="col-span-2">
                <label for="link2" class="font-semibold">Link 2 (*)</label>
                <input required v-model="link2" placeholder="" class="shadow-none with-border" id="link" type="text" autocomplete="off">
              </div>
              <div class="col-span-2">
                <button type="submit"
                      class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">Hoán đổi file nhạc
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import {changePageTitle, canManageTag, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "SwapSong",
  data() {
    return {
      link1: "",
      link2: ""
    }
  },
  methods: {
    swapSong() {
      let query = `mutation($link1: String!, $link2: String!) {
        swapSong(link1: $link1, link2: $link2)
      }`;

      ApiService.graphql(query, {link1: this.link1, link2: this.link2})
        .then(({data}) => {
          if (data.data && data.data.swapSong) {
            this.link1 = "";
            this.link2 = "";
            this.$toast.success("Hoán đổi file nhạc thành công");
          } else if(data.errors) {
            this.$toast.error("Hoán đổi file nhạc không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Hoán đổi file nhạc không thành công: " + response.message);
        });
    }
  },
  mounted() {
    if (!canManageTag()) {
      this.$router.push({name: "Home"});
      return;
    }
    changePageTitle("Hoán đổi file nhạc");
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  }
}
</script>
